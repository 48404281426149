<template>
  <div id="promotion"
       class="promotion"
  >
    <m-breadcrumbs />
    <json-ld-breadcrumb />
    <div class="o-category-root-page__title">
      <SfHeading
        :title="pageTitle"
        :level="1"
        class="o-category-root-page-title__heading"
      />
    </div>
    <div class="promotion-content">
      <div class="filters">
        <div
          class="filters-filter filters-filter--category"
          v-click-outside="hideFilterCategory"
        >
          <SfButton
            class="filters__button"
            :class="{ '_active': isActiveFilterCategory }"
            data-transaction-name="Promotion - Toggle Filter"
            @click="toggleFilterCategory"
          >
            <span class="filter__button-label">{{ $t("Categories") }}</span>
            <span class="filter__button-value">{{ titleFilterCategory }}</span>
          </SfButton>
          <div class="filters-categories">
            <MPromotionFilter
              :items="getPromotionCategory.items"
              :is-active="isActiveFilterCategory"
              @select="selectCategory"
              :current="getPromotionCurrentCategory"
              :header-title="$t('All categories')"
            />
          </div>
        </div>
        <div
          class="filters-filter filters-filter--status"
          v-click-outside="hideFilterStatus"
        >
          <SfButton
            class="filters__button"
            :class="{ '_active': isActiveFilterStatus }"
            data-transaction-name="Promotion - Toggle Filter - Status"
            @click="toggleFilterStatus"
          >
            <span class="filter__button-label">{{ $t("Promotion type") }}</span>
            <span class="filter__button-value">{{ titleFilterType }}</span>
          </SfButton>
          <div class="filters-type">
            <MPromotionFilter
              :items="statusFilter"
              :is-active="isActiveFilterStatus"
              @select="selectFiltersType"
              :current="getPromotionCurrentType"
              :header-title="$t('Weekly variety')"
            />
          </div>
        </div>
      </div>
      <div class="promotion-list">
        <MPromotionProduct
          v-for="(page, key) in firstPromotionPage"
          :key="key"
          :item="page"
          :start="start"
          :is-lazy="false"
          :is-priority="true"
        />
      </div>
      <div :id="productOffersCategoriesWidgetId" />
      <template v-if="shouldShowPromotionSlider">
        <OSection
          class="carousel o-category-root-page__special-promotions"
          :title-heading="$t(`VARUS special promotions`)"
          is-centered
          is-not-mobile-padding
        >
          <template #link>
            <SfLink :link="localizedRoute('/rasprodazha')"
                    class="sf-link--primary"
            >
              {{ $t(`All goods`) }}
            </SfLink>
          </template>
          <MProductCarousel
            :products="offersCategoriesProducts"
            arrow-color="white"
            :key="offersCategoriesProducts.length"
          />
        </OSection>
      </template>
      <div class="promotion-list">
        <MPromotionProduct
          v-for="(page, key) in lastPromotionPage"
          :key="key"
          :item="page"
          :start="start"
        />
      </div>
      <template v-if="shouldShowBrandsSlider">
        <OSection
          :title-heading="$t('Promotions from partners')"
          :background="`#F3F4F6`"
          is-not-mobile-padding
          is-centered
          class="special-promotions-brands"
        >
          <MBrandsCarouselHomePage
            :brands-list="brandsList"
            brands-type="promotion"
            arrow-color="white"
          />
        </OSection>
      </template>
      <div class="promotion-list">
        <MPromotionProduct
          v-for="(page, key) in endPromotionPage"
          :key="key"
          :item="page"
          :start="start"
        />
      </div>
      <no-ssr>
        <div class="bottom-plp-block">
          <OPagination
            v-if="totalPages > 1"
            :current-page="currentPage"
            :total="totalPages"
            :scroll-top="true"
            :per-page="perPage"
            :is-load-more="isAdditionalLoading"
            @nav-clicked="onPaginationClick"
            @load-more="onLoadMore"
            @page-changed="onPageChanged"
          />
        </div>
      </no-ssr>
      <ASmoothReflow
        class="category-footer__wrapper"
      >
        <div class="category-footer">
          <MRecentlyViewedProducts />
          <MAdvantages />
          <OSection
            v-if="false"
            :title-heading="$t('Cook with VARUS')"
            class="section"
          >
            <template #link>
              <SfLink link="#" target="_blank"
                      class="sf-link--primary"
              >
                {{ $t("Read recipes") }}
              </SfLink>
            </template>
            <MRecipeCarousel class="flex" />
          </OSection>
          <div class="center">
            <MSeoBlock :seo-html="getSeoHtml" />
          </div>
        </div>
      </ASmoothReflow>
    </div>
  </div>
</template>

<script>
import config from 'config';
import { mapState, mapActions } from 'vuex';
import { SfButton, SfLink, SfHeading } from '@storefront-ui/vue';
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { loadRelatedProducts } from 'theme/helpers';
import MBreadcrumbs from 'theme/components/molecules/m-breadcrumbs';
import JsonLdBreadcrumb from 'theme/components/json-ld/json-ld-breadcrumb';
import OSection from 'theme/components/organisms/o-section';
import MProductCarousel from 'theme/components/molecules/m-product-carousel';
import MBrandsCarouselHomePage from 'theme/components/molecules/m-brands-carousel-home-page.vue';
import MPromotionProduct from 'theme/components/molecules/m-promotion-product';
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow';
import MRecentlyViewedProducts from 'theme/components/molecules/m-recently-viewed-products';
import MAdvantages from 'theme/components/molecules/m-advantages';
import MRecipeCarousel from 'theme/components/molecules/m-recipe-carousel';
import MSeoBlock from 'theme/components/molecules/m-seo-block';
import MPromotionFilter from 'theme/components/molecules/m-promotion-filter';
import { metaPromotion } from 'theme//meta/promotion';
import { getFormatTimer } from 'theme/helpers/timer';
import { clickOutside } from '@storefront-ui/vue/src/utilities/directives';
import NoSSR from 'vue-no-ssr'

const THEME_PAGE_SIZE = 12;

export default {
  name: 'Promotion',
  components: {
    MBreadcrumbs,
    JsonLdBreadcrumb,
    SfHeading,
    SfLink,
    SfButton,
    OSection,
    MProductCarousel,
    MBrandsCarouselHomePage,
    MPromotionProduct,
    ASmoothReflow,
    MRecentlyViewedProducts,
    MAdvantages,
    MRecipeCarousel,
    MSeoBlock,
    MPromotionFilter,
    OPagination: () => process.browser ? import('theme/components/organisms/o-pagination') : null,
    'no-ssr': NoSSR
  },
  provide () {
    const provided = {};

    // Reactive injection
    Object.defineProperties(provided, {
      activeCategoryId: {
        get: () => 0
      }
    })

    return { provided };
  },
  directives: {
    clickOutside
  },
  data () {
    return {
      statusFilter: [
        {
          name: this.$t('Online only'),
          slug: 'online',
          id: 1,
          type: 'type'
        }
      ],
      categoryRelatedProducts: [],
      isActiveFilterCategory: false,
      isActiveFilterStatus: false,
      titleFilterCategory: this.$t('All categories'),
      titleFilterType: this.$t('Weekly variety'),
      timerObj: {},
      start: 0
    }
  },
  async asyncData ({ store, route }) {
    await Promise.all([
      store.dispatch('breadcrumbs/set', {
        current: currentStoreView().storeCode === 'ua' ? 'Акції' : 'Акции',
        routes: []
      }, { root: true }),
      store.dispatch('promotion/fetchPromotionCollection', { route }),
      store.dispatch('homepage/loadAdvantagesContent'),
      store.dispatch('promotion/fetchMainBrandsCollection'),
      store.dispatch('promotion/fetchPromotionMetaData'),
      store.dispatch('ui/loadCatalogMenu'),
      store.dispatch('brands/fetchPromotionBrandsList')
    ]);
  },
  async beforeRouteLeave (to, from, next) {
    await this.loadMore(false)
    next();
  },
  computed: {
    ...mapState({
      getPromotionCategory: state => state.promotion.promotionCategory,
      promotions: state => state.promotion.promotions,
      searchProductsStats: state => state.promotion.searchProductsStats,
      getPromotionMetaData: state => state.promotion.promotionMetaData,
      getPromotionCurrentCategory: state => state.promotion.promotionCurrentCategory,
      getPromotionCurrentType: state => state.promotion.promotionCurrentType,
      getPromotionBrandsList: state => state.brands.promotionBrandsList,
      isAdditionalLoading: state => state['promotion'].isAdditionalPromotionsLoading
    }),
    brandsList () {
      return this.getPromotionBrandsList?.map(e => {
        return {
          ...e,
          url_key: e.url_key + '/?has_promotion_in_stores'
        }
      }) || []
    },
    getSeoHtml () {
      return this.getPromotionMetaData.category_seo_block_text
    },
    productOffersCategoriesWidgetId () {
      return config.esputnik?.widgetIds?.productOffersCategories;
    },
    offersCategoriesProducts () {
      return this.categoryRelatedProducts.length ? this.categoryRelatedProducts : [];
    },
    getSortedPromotionItems () {
      const items = this.promotions || [];
      const sortedItems = items.sort((a, b) => new Date(a.date_to) - new Date(b.date_to));

      const { activeItems, nonActiveItems } = sortedItems.reduce((acc, item) => {
        item.timerDistance = getFormatTimer(item.date_to)?.distance || 0;
        if (item.timerDistance > 0) {
          acc.activeItems.push(item);
        } else {
          acc.nonActiveItems.push(item);
        }
        return acc;
      }, { activeItems: [], nonActiveItems: [] });

      return [...activeItems, ...nonActiveItems];
    },
    firstPromotionPage () {
      return this.getSortedPromotionItems.slice(0, 4)
    },
    lastPromotionPage () {
      return this.getSortedPromotionItems.slice(4, 8)
    },
    endPromotionPage () {
      return this.getSortedPromotionItems.slice(8)
    },
    totalPages () {
      return Math.ceil(this.searchProductsStats?.total.value / THEME_PAGE_SIZE);
    },
    getSelectedCategoryId () {
      if (!this.getPromotionCurrentCategory.hasOwnProperty('cat')) return 0

      return Array.isArray(this.getPromotionCurrentCategory.cat)
        ? Number(this.getPromotionCurrentCategory.cat[0])
        : Number(this.getPromotionCurrentCategory.cat)
    },
    getSelectedCategory () {
      return this.getPromotionCategory?.items?.find(elem => elem.id === this.getSelectedCategoryId) || null
    },
    pageTitle () {
      return this.$t(`Stock`)
    },
    shouldShowSlider () {
      const { page, type, cat } = this.$route.query;

      const isPaginationUsed = page && page > 1;
      const isTypeFilterUsed = type && type.length > 0;
      const isCategoryFilterUsed = cat && cat.length > 0;

      return !isPaginationUsed && !isTypeFilterUsed && !isCategoryFilterUsed;
    },
    shouldShowBrandsSlider () {
      return this.shouldShowSlider && this.brandsList.length
    },
    shouldShowPromotionSlider () {
      return this.shouldShowSlider && this.offersCategoriesProducts.length
    },
    perPage () {
      return THEME_PAGE_SIZE;
    },
    currentPage () {
      return Number(this.$route.query.page) || 1;
    }
  },
  mounted () {
    this.getTitleFilterCategory()
    this.getTitleFilterType()
    window.addEventListener('productOffersCategoriesLoaded', this.getProductOffersCategories)
  },
  updated () {
    window.addEventListener('productOffersCategoriesLoaded', this.getProductOffersCategories)
  },
  beforeDestroy () {
    window.removeEventListener('productOffersCategoriesLoaded', this.getProductOffersCategories)
  },
  methods: {
    ...mapActions({
      setProductsLoading: 'promotion/setProductsLoading',
      loadMore: 'promotion/loadMorePromotions'
    }),
    async getProductOffersCategories () {
      this.categoryRelatedProducts = await loadRelatedProducts(
        this.productOffersCategoriesWidgetId,
        config?.entities?.product?.carouselSize,
        true
      ) || []
    },
    toggleFilterCategory () {
      this.isActiveFilterCategory = !this.isActiveFilterCategory
    },
    toggleFilterStatus () {
      this.isActiveFilterStatus = !this.isActiveFilterStatus
    },
    hideFilterCategory () {
      this.isActiveFilterCategory = false
    },
    hideFilterStatus () {
      this.isActiveFilterStatus = false
    },
    async selectCategory (category) {
      await this.loadMore(false)
      const isCategoryNotNull = category !== null;
      const filter = {
        id: isCategoryNotNull ? category.id : 0,
        label: isCategoryNotNull ? category.name : 'all',
        type: 'cat'
      };
      this.titleFilterCategory = isCategoryNotNull
        ? this.getPromotionCategory.items.find(elem => elem.id === category.id).name
        : this.$t('All categories');
      this.$store.dispatch('promotion/switchFilters', [filter]);
      this.toggleFilterCategory();
    },
    getTitleFilterCategory () {
      if (this.getPromotionCurrentCategory.hasOwnProperty('cat')) {
        const id = Array.isArray(this.getPromotionCurrentCategory.cat) ? Number(this.getPromotionCurrentCategory.cat[0])
          : Number(this.getPromotionCurrentCategory.cat)
        if (id !== 0) {
          this.titleFilterCategory = this.getPromotionCategory.items.filter(elem => elem.id === id)[0].name
        } else {
          this.titleFilterCategory = this.$t('All categories');
        }
      }
    },
    getTitleFilterType () {
      if (this.getPromotionCurrentType.hasOwnProperty('type')) {
        const id = Array.isArray(this.getPromotionCurrentType.type) ? Number(this.getPromotionCurrentType.type[0])
          : Number(this.getPromotionCurrentType.type)
        if (id !== 0) {
          this.titleFilterType = this.statusFilter.filter(elem => elem.id === id)[0].name
        } else {
          this.titleFilterType = this.$t('Weekly variety');
        }
      }
    },
    async selectFiltersType (type) {
      await this.loadMore(false)
      const isTypeNotNull = type !== null;
      const filter = {
        name: isTypeNotNull ? this.statusFilter.filter(elem => elem.id === type.id)[0].name : this.$t('Weekly variety'),
        slug: isTypeNotNull ? type.slug : 'all',
        id: isTypeNotNull ? type.id : 0,
        type: 'type'
      };
      this.titleFilterType = filter.name;
      this.$store.dispatch('promotion/switchFiltersType', [filter]);
      this.toggleFilterStatus();
    },
    onPaginationClick () {
      this.setProductsLoading(true)
    },
    onLoadMore () {
      this.loadMore(true);
    },
    onPageChanged () {
      this.loadMore(false);
    }
  },
  metaInfo: metaPromotion
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~theme/css/mixins";

.o-section {
  width: 100%;
}

.promotion {
  max-width: var(--max-width);
  box-sizing: border-box;
  margin: 0 auto;
  font-family: var(--font-family-inter);

  &-content {
    .filters {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: var(--spacer-15);
      padding: 0 var(--spacer-16);
      box-sizing: border-box;

      &-filter {
        width: calc(50% - var(--spacer-5));
        position: relative;
      }

      &__button {
        width: 100%;
        height: var(--spacer-50);
        padding: var(--spacer-8) var(--spacer-15);
        background-color: var(--light-gray);
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 5px;
        position: relative;

        &:after {
          content: '';
          width: .3rem;
          height: .3rem;
          border-bottom: 2px solid var(--white);
          border-right: 2px solid var(--white);
          transform: rotate(45deg);
          right: var(--spacer-sm);
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
        }

        &._active {
          &:after {
            transform: rotate(-135deg);
          }
        }

        &:after {
          border-bottom: 2px solid var(--black);
          border-right: 2px solid var(--black);
        }
      }

      &-categories, &-type {
        position: absolute;
        width: 100%;
        left: 0;
        background: var(--white);
        border-bottom-left-radius: var(--spacer-10);
        border-bottom-right-radius: var(--spacer-10);
        z-index: 2;
        overflow: auto;
        box-shadow: 0 4px 11px rgba(var(--c-dark-base), 0.1);

        ::v-deep .accordion {
          font-family: var(--font-family-inter);
          display: block;
          width: auto;
          margin-top: var(--spacer-5);
          max-height: px2rem(340);
          overflow-y: scroll;
          overflow-x: hidden;
          align-items: center;
          padding-right: var(--spacer-5);
          padding-left: var(--spacer-5);
          padding-bottom: var(--spacer-20);
          background: var(--light-gray);
          border-radius: var(--spacer-10);
          scrollbar-width: thin;
          scrollbar-color: var(--black);
          box-shadow: var(
              --select-dropdown-box-shadow,
              0 4px 11px rgba(var(--c-dark-base), 0.1)
          );

          @include for-desktop {
            padding-right: 0;
            padding-left: 0;
          }

          &::-webkit-scrollbar {
            width: 3px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgba(235, 103, 71, 0.3);
          }

          &:hover {
            &::-webkit-scrollbar-thumb {
              background-color: var(--orange);
            }
          }

        &-item {

          .accordion-item__header {
            width: 100%;
            transition: none;
          }

          &:first-child {
            @include for-desktop {
              padding-top: var(--spacer-10);
              margin-left: var(--spacer-20);
            }

            & > .accordion-item__header {
              @include for-mobile {
                padding-left: var(--spacer-15);
              }
            }
          }

          &:not(:first-child) {
            margin-left: var(--spacer-15);

            @include for-desktop {
              margin-left: var(--spacer-20);
              margin-right: var(--spacer-20);
            }
          }

          &__header {
            color: var(--black);
            white-space: normal;
            padding: var(--spacer-20) 0 0 0;
            font-style: normal;
            font-weight: normal;
            font-size: var(--font-sm);
            display: inline-block;
            border-radius: var(--spacer-10);
            position: relative;
            transition: color 0.3s ease-in-out;
            cursor: pointer;

            .accordion-item__label {
              position: relative;

              &-count {
                color: var(--neutral-gray);
                padding-left: var(--spacer-10);
                position: absolute;
                left: 100%;
                bottom: 0;
                transition: color .3s ease-in-out;
              }
            }

            &.is-open {
              color: var(--orange);
              background-color: inherit;

              .accordion-item__label {

                &-count {
                  color: var(--orange);
                  transition: color .3s ease-in-out;
                }
              }
            }

            @include for-desktop {
              &:hover {
                color: var(--orange-hover);

                .accordion-item__label {

                  &-count {
                    color: var(--orange);
                    transition: color .3s ease-in-out;
                  }
                }
              }
            }

            &--active {
              color: var(--orange);

              .accordion-item__label {

                &-count {
                  color: var(--orange);
                  transition: color .3s ease-in-out;
                }
              }
            }
          }

          .sf-accordion-item__content {
            padding-bottom: 0;
            padding-top: 0;
            border: none;
            border-left: 1px solid var(--gray);

            & > .accordion--sub {
              margin-left: var(--spacer-20);

              .accordion-item__header.is-open {
                border-radius: 0;
              }
            }
          }

          .accordion-item__first-level + .sf-accordion-item__content {
            border-left: none;
            position: relative;
          }

          &:last-child {
            @include for-desktop {
              padding-bottom: var(--spacer-20);
            }
          }
        }
        }
      }
    }

    &-lists {
      flex: 0 0 100%;
      padding: 0 var(--spacer-10);
      order: 3;
      box-sizing: border-box;

      @include for-tablet {
        flex: 1 1;
        width: 73%;
        padding: 0 var(--spacer-18);
      }

      @include for-desktop {
        flex: 1 1;
        width: 73%;
        padding: var(--spacer-sm) var(--spacer-sm) var(--spacer-sm) var(--spacer-64);
      }
    }
  }

  &-filter-category {
    flex: 0 0 100%;
    order: 1;

    @include for-desktop {
      flex: 0 0 13.75rem;
      padding: var(--spacer-sm);
      order: 2;
    }
  }

  .special-promotions {

    &-brands {
      @include for-desktop {
        box-shadow: -40vw 0 var(--light-gray), 40vw 0 var(--light-gray);
      }
    }
  }

  &-list {
    margin-top: var(--spacer-20);
    padding: 0 var(--spacer-10);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (min-width: $tablet-min) {
      padding: 0 var(--spacer-16);
    }
  }

  .bottom-plp-block {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

.filter__button-label {
  color: var(--dark-gray);
  font-size: var(--font-size-12);
  margin-bottom: var(--spacer-5);
}

.filter__button-value {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  text-align: left;
  font-size: var(--font-size-14);
  color: var(--black);
}

.o-category-root-page__special-promotions {
  ::v-deep {
    .sf-carousel {
      .glide__slide {
        .sf-product-card {
          max-width: 100%;
          padding-bottom: var(--spacer-20);
        }
      }
    }
  }
}

.o-category-root-page-title {
  &__heading {
    display: inline-block;
    text-align: left;
    padding: 0 var(--spacer-15) 0 var(--spacer-10);

    ::v-deep .sf-heading__title {
      @include header-title;
    }

    @include for-tablet {
      padding: 0 var(--spacer-10) 0 var(--spacer-18);
    }

    @include for-desktop {
      padding: 0 var(--spacer-10) 0 var(--spacer-sm);
    }
  }

  &__total-products {
    font-size: var(--font-size-13);
    color: var(--dark-gray);
    display: inline-block;
  }
}

.o-category-root-page__special-promotions {
  box-shadow: -40vw 0 var(--light-gray), 40vw 0 var(--light-gray);
  background: var(--light-gray);
}

.category-footer {
  ::v-deep {
    .m-seo-block__content {
      font-size: var(--font-size-14);
      color: var(--dark-gray);
    }
  }
}

::v-deep {
  .o-section--center {
    --section-center-padding: 0;
  }

  .o-section__heading {
    --section-heading-padding:  0 var(--spacer-10) var(--spacer-15);

    @media (min-width: $tablet-min) {
      --section-heading-padding: 0 var(--spacer-16) var(--spacer-20);
    }

    @media (min-width: $desktop-min) {
      --section-heading-padding: 0 150px var(--spacer-20) var(--spacer-16);
    }
  }
}

</style>
